
import { reactive, defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { Toast } from "vant";
import { resetPassword, logout } from "@/api/web";

export default defineComponent({
  setup() {
    const state = reactive({
      newPwd: "",
      reEnterPwd: "",
      globalAccount: "",
    });

    const loginToast = () => {
      const toast = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "您已重置密码,3秒后将返回登录页面",
      });
      let second = 3;
      const timer = setInterval(() => {
        second--;
        if (second) {
          toast.message = `您已重置密码,${second}秒后将返回登录页面`;
        } else {
          clearInterval(timer);
          Toast.clear();
          router.replace("login");
        }
      }, 1000);
    };

    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const onSubmit = async () => {
      state.globalAccount = (route.params as any).globalAccount;
      const res = await resetPassword({ ...state });
      if ((res as any).resp_code === 0) {
        loginToast();
        logout();
        store.commit('setToken', null);
      }
    };

    const validFailed = (err: any) => {
      Toast({
        type: "fail",
        message: err.errors[0].message,
      });
    };

    const validPassword = (value: string) => {
      return !!value.match(/^(?=.*[A-Za-z])||(?=.*\d)[A-Za-z\d]{6,14}$/);
    };

    const validAgainPassword = (value: string) => {
      return value === state.newPwd;
    };

    let btnName = ref("eye-o");
    let reBtnName = ref("eye-o");
    let passwordType = ref("password");
    let rePasswordType = ref("password");

    const changeBtnType = () => {
      if (btnName.value === "eye-o") {
        btnName.value = "closed-eye";
        passwordType.value = "";
      } else if (btnName.value === "closed-eye") {
        btnName.value = "eye-o";
        passwordType.value = "password";
      }
    };

    const changeReBtnType = () => {
      if (reBtnName.value === "eye-o") {
        reBtnName.value = "closed-eye";
        rePasswordType.value = "";
      } else if (reBtnName.value === "closed-eye") {
        reBtnName.value = "eye-o";
        rePasswordType.value = "password";
      }
    };

    return {
      state,
      onSubmit,
      validFailed,
      validPassword,
      validAgainPassword,
      btnName,
      reBtnName,
      passwordType,
      rePasswordType,
      changeBtnType,
      changeReBtnType,
    };
  },
});
