<template>
  <div class="reset">
    <div class="head">
      <div class="title">重置密码</div>
      <div class="subTitle">请设置6-14位字符为密码</div>
    </div>
    <van-form
      class="vanForm"
      @submit="onSubmit"
      @failed="validFailed"
      :show-error="false"
      :show-error-message="false"
      :validate-first="true"
    >
      <van-field
        clearable
        v-model.trim="state.newPwd"
        :type="passwordType"
        name="newPwd"
        label="新密码"
        placeholder="请输入新密码"
        :rules="[
          { required: true, message: '请输入新密码' },
          {
            required: true,
            validator: validPassword,
            message: '密码格式不正确',
          },
        ]"
      >
        <template #button>
          <van-icon
            v-show="state.newPwd"
            :name="btnName"
            size="18px"
            @click="changeBtnType"
          />
        </template>
      </van-field>
      <van-field
        clearable
        v-model.trim="state.reEnterPwd"
        :type="rePasswordType"
        name="reEnterPwd"
        label="再次输入"
        placeholder="请再次输入新密码"
        :rules="[
          { required: true, message: '请再次输入新密码' },
          { message: '两次输入密码不一致!', validator: validAgainPassword },
        ]"
      >
        <template #button>
          <van-icon
            v-show="state.reEnterPwd"
            :name="reBtnName"
            size="18px"
            @click="changeReBtnType"
          />
        </template>
      </van-field>
      <div class="sub">可以为数字、字母或数字与字母组合，字母区分大小写</div>

      <div style="margin: 16px">
        <van-button round block type="primary" native-type="submit"
          >保存</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script lang="ts">
import { reactive, defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { Toast } from "vant";
import { resetPassword, logout } from "@/api/web";

export default defineComponent({
  setup() {
    const state = reactive({
      newPwd: "",
      reEnterPwd: "",
      globalAccount: "",
    });

    const loginToast = () => {
      const toast = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "您已重置密码,3秒后将返回登录页面",
      });
      let second = 3;
      const timer = setInterval(() => {
        second--;
        if (second) {
          toast.message = `您已重置密码,${second}秒后将返回登录页面`;
        } else {
          clearInterval(timer);
          Toast.clear();
          router.replace("login");
        }
      }, 1000);
    };

    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const onSubmit = async () => {
      state.globalAccount = (route.params as any).globalAccount;
      const res = await resetPassword({ ...state });
      if ((res as any).resp_code === 0) {
        loginToast();
        logout();
        store.commit('setToken', null);
      }
    };

    const validFailed = (err: any) => {
      Toast({
        type: "fail",
        message: err.errors[0].message,
      });
    };

    const validPassword = (value: string) => {
      return !!value.match(/^(?=.*[A-Za-z])||(?=.*\d)[A-Za-z\d]{6,14}$/);
    };

    const validAgainPassword = (value: string) => {
      return value === state.newPwd;
    };

    let btnName = ref("eye-o");
    let reBtnName = ref("eye-o");
    let passwordType = ref("password");
    let rePasswordType = ref("password");

    const changeBtnType = () => {
      if (btnName.value === "eye-o") {
        btnName.value = "closed-eye";
        passwordType.value = "";
      } else if (btnName.value === "closed-eye") {
        btnName.value = "eye-o";
        passwordType.value = "password";
      }
    };

    const changeReBtnType = () => {
      if (reBtnName.value === "eye-o") {
        reBtnName.value = "closed-eye";
        rePasswordType.value = "";
      } else if (reBtnName.value === "closed-eye") {
        reBtnName.value = "eye-o";
        rePasswordType.value = "password";
      }
    };

    return {
      state,
      onSubmit,
      validFailed,
      validPassword,
      validAgainPassword,
      btnName,
      reBtnName,
      passwordType,
      rePasswordType,
      changeBtnType,
      changeReBtnType,
    };
  },
});
</script>

<style lang="scss" scoped>
.reset {
  background: #fff;
  height: 100vh;
  /* If you need to support browser without CSS var support (<= IE11) */
  height: calc(100vh - var(--vh-offset, 0px));
  .head {
    height: 25%;
    padding-left: 34px;
    padding-top: 118px;
    .title {
      font-size: 28px;
      font-weight: bold;
      line-height: 40px;
      color: #363737;
    }
    .subTitle {
      font-size: 16px;
      line-height: 22px;
      color: #aaaaaa;
    }
  }
  .sub {
    padding: 16px;
    font-size: 14px;
    line-height: 20px;
    color: #aaaaaa;
  }
  .vanForm {
    padding: 0 16px;
  }

  :deep .van-field__button {
    display: flex;
  }
}
</style>
